import './App.css';
import React, { Component } from 'react';

class App extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      showingterms: false,
      page: "Home"
    }
  }

  navbar() {
    return (
      <div className="Card-Navbar">
        <u onClick = {() => this.setState({page: "Home"})}>Homepage</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Details"})}>Details</u> •&nbsp;
        <u onClick = {() => this.setState({page: "AboutMe"})}>About Me</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Services"})}>About Group</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Downloads"})}>Downloads</u>
      </div>
    )
  }

  currentpage() {
    switch(this.state.page)
    {
      case "Home":
        return HomePage();

      case "Details":
        return Details();

      case "AboutMe":
        return AboutMe();

      case "Services":
        return MyServices();

      case "Downloads":
        return Downloads();

      default:
        return HomePage();
    }
  }

  //Don't forget to change cursor to pointer

  render() {
    return (
      <div className="Container">
        <div className="Title"><img className="s-logo" src="whiteoutline.png" /><div className="text-logo">IFACT</div></div>
        <div className="Subtext">Family Support Group for those who have loved ones with substance use and/or mental health issues.</div>
        <div className="Card">
            {this.state.showingterms ? this.navbar() : this.navbar()}
            {this.state.showingterms ? this.currentpage() : this.currentpage()}
          <div className="Card-Footer">Family Support Group will always be free of charge.</div>
        </div>
      </div>
    );
  }
}

function HomePage() {
  return (
    <>
      <div className="Card-Header">• Family Support Group •</div>
      <div className="Card-Body">
        <div className="Contact">Shelley Munnee • +61 497 332 113 • shelley@sifact.com.au</div>
        <hr />
        <div className="Details">
          It is paramount to me, to provide a family support group for those who have loved ones with substance use and/or mental health issues. Throughout my life's journey I have navigated my way through the troublesome times, the rollercoaster ride of highly charged emotions, traumatic situations, and the disintegration of the way family life used to be.
          <hr />
          Family Support Groups have been proven by research to be a valuable way, in bonding with members who are in similar situations, to navigate their way more effectively.
          It has been a privilege for me to see the personal growth and resillience gained by family support group members over the years. It is life-changing.
          <hr />
          If you are interested in attending a family support group meeting, and I highly recommend it,&nbsp;
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfKQi-2C-689sUI07WAvp5MK1MiPKEL6aHGja295y7CiVvRLw/viewform?usp=sf_link">click this link to register!</a>&nbsp;
          Once I receive your registration form, I'll add you to the list, and I will look forward to meeting you!
        </div>
      </div>
    </>
  )
}

function Details() {
  return (
    <>
      <div className="Card-Header">Where and When</div>
      <div className="Card-Body">
        <div>
          6:00-7:30pm Held Every Wednesday from the 4th of January 2023.
          <hr />
          Parking is avaliable on Chelmsford Rd both sides of William St, also along William St. When you arrive and walk through the security gate go to the front door of the building on your left. Please take a seat in the waiting room.
          <hr />
          <iframe id="ggmaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1060.3100048739086!2d115.8662239201683!3d-31.935441190063116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32bac654cd23af%3A0xd69262c303f26ca6!2s544%20William%20St%2C%20Mount%20Lawley%20WA%206050!5e1!3m2!1sen!2sau!4v1670992167209!5m2!1sen!2sau" style={{border: 0}} allowfullscreen="" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </>
  )
}

function AboutMe() {
  return (
    <>
      <div className="Card-Header">About Me</div>
      <div className="Card-Body">
        <div>
          I'm an experienced counsellor and I have my post-grad diploma in family and relationships therapy. 
          I have an extensive history in AOD (Alcohol and Other Drugs) counselling and in facilitating Family Support Group therapy, having worked at an AOD agency for 10½ years.
          <hr />
          I also have the lived experience of loved ones with substance use and/or mental health issues.
          My lived experience has given me the empathy, wisdom, understanding, and learning required to help to facilitate you and your family members through the troublesome, turbulent, and traumatic situations that can occur on your journey. It has always been a privilege to me over the years to witness the personal and familial growth along the way.
        </div>
      </div>
    </>
  )
}

function MyServices() {
  return (
    <>
      <div className="Card-Header">About Group</div>
      <div className="Card-Body">
        <div>
          In my experience of facilitating Family Support Group meetings over the last 8 years, I have found that providing a warm welcoming environment is very important to family group members, who have often been faced with a lot over the past week.
          I have found that having a cheeseboard has been a wonderful way of creating a casual vibe, for those who might have just arrived from work, as well as those who might just want to enjoy a nibble.
          I can assure those considering attending, something that resonated deeply within me, was witnessing the long standing Family Support Group members creating a bond with the new members that lifted them, making them feel accepted, and an important part of the group. No more feeling isolated, lonely, or helpless.
          Empowering for all.
          <hr />
          <b>Group Rules:</b>
          <br />
          <div className="rules">
            <b>• Confidentiality</b>; everything discussed within group <b>must</b> be kept confidential.
            <br />
            <b>• Providing a safe space</b>; where you can feel comfortable, open, and authentic.
            <br />
            <b>• Respect other's privacy</b>; allow them to share at their own pace.
            <br />
            <b>• Equal voice</b>; everyone is provided with an opportunity to speak.
            <br />
            <b>• Listen respectfully</b>; only one person speaks at a time, no side conversations.
            <br />
            <b>• Being non-judgemental</b>; everyone is at a different place in their journey.
            <br />
            <b>• It's okay to be silent</b>; at times all we can do is co-exist.
          </div>
        </div>
      </div>
    </>
  )
}

function Downloads() {
  return (
    <>
      <div className="Card-Header">Downloads</div>
      <div className="Card-Body">
        <div>
          <a href="https://fsg.sifact.com.au/flyer.png">Family Support Group Flyer</a>
          <hr />
          More coming soon!
        </div>
      </div>
    </>
  )
}

export default App;